// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'Demo',
  tag: 'demo',
  appVersion: require('../../package.json').version,
  production: false,
  isDev: false,
  allowedDomains: ['api.demo.gaia-engine.com'],
  disallowedRoutes: [],
};

export const API_URL = 'https://api.demo.gaia-engine.com';
export const MAPBOX_KEY = 'pk.eyJ1IjoiYW50b25pb2dhaWEiLCJhIjoiY2w4Y3ZqMXl5MTMybTN1cWtvcnN5b2V3dSJ9.z2viUrREXoaWm5CNHGOWmA';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
